import React from 'react';
import i18next from 'i18next';

const Partners = ({ partner, setPartnerInfo }) => {
    let selectLanguage = i18next.language;

    return (
        <div className=" border p-4 shadow rounded bg-white flex flex-col items-center">
            <img className="w-36 h-12 object-contain" src={partner.carrier_logo} alt="" />

            <h2 className="text-lg py-2 font-semibold">{selectLanguage === "ar" ? partner.title_arabic : partner.title}</h2>

            <div className="self-start w-full">
                <div>
                    <ul className='mt-2'>
                        <li className=''>
                            <span title='Approximate Delivery Time' className='cursor-pointer'>* ADT : {selectLanguage === "ar" ? partner.approximate_delivery_time_arabic : partner.approximate_delivery_time}</span>
                        </li>
                    </ul>
                </div>
                <div className="flex flex-col gap-y-2">
                    <div className="grid grid-cols-4 gap-x-1 mt-3">
                        <label className="label cursor-pointer">
                            Active :
                        </label>
                        <input type="checkbox" onClick={(e) => setPartnerInfo(partner, Number(e.target.checked))} defaultChecked={partner?.is_enabled} className="toggle toggle-primary col-span-3 mt-2" />

                    </div>

                </div>
            </div>
        </div>
    );
};

export default Partners;