import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import LoadingSpinner from "../../../components/utils/LoadingSpinner";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Partners from "./Partners";
import FilterComponent2 from "../../../components/utils/FilterComponent2";
import axios from "../../../components/utils/Interceptors";
import "react-responsive-modal/styles.css";

const ShippingMethods = () => {
  const { t } = useTranslation();
  let selectLanguage = i18next.language;
  const [courierPartners, setCourierPartners] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(true);
  const [storeDetails, setStoreDetails] = useState([]);
  const [showShip, setShowShip] = useState(false);

  useEffect(() => {
    const getstoreDetails = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/getstorefulldetails`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.data) {
        const store = response.data;
        if(store.torod_user_id && store.torod_warehouse_id && store.torod_shipping_enabled){
          setShowShip(true)
        }
        setStoreDetails(response.data);
      }
      setLoading(false);
    };
    getstoreDetails();
  }, []);

  useEffect(() => {
    
    const getAllshippings = async () => {
        const res = await axios.get(
          `${process.env.REACT_APP_APIURL}/vendor/getallshippingsetting`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.token}`,
            },
          }
        );
        const response = await res.data;
        if (response.data) {
          getAllCourierPartners(response.data);
        } else {
          toast.error("something went wrong while fetching shipping settings");
        }
      }

    const getAllCourierPartners = async (shippings) => {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}/vendor/torod/courierpartners`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      const response = await res.data;
      if (response.status_code == 400) {
        toast.error(response.message);
      } else {
        response.map((partner) => {
          let findPartner = shippings.find(
            (shi) => shi.torod_shipping_id == partner.id
          );
          if (findPartner?.is_enabled == 1) {
            partner["is_enabled"] = true;
          }
        });
        setCourierPartners(response);
      }
    };
    if(showShip){
      getAllshippings();
    }
  }, [showShip]);

  const setPartnerInfo = async (partner, e) => {
    const data = {
      torod_shipping_id: partner.id,
      is_enabled: e,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_APIURL}/vendor/createshippingsetting`,
      JSON.stringify(data),
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response = await res.data;
    if (response.status_code == 200) {
      toast.success(response.message);
    } else {
      toast.error(response.err || response.message);
    }
  };

  const createShippingWarehouse = async () => {
    setLoading(true);
    const res3 = await axios.get(
      `${process.env.REACT_APP_APIURL}/vendor/createtorodwarehouse`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response3 = await res3.data;
    if (response3.status_code == 200) {
      toast.success(response3.message);
    } else {
      if (typeof response3.message === "string") {
        toast.error(response3.message);
      } else {
        toast.error(JSON.stringify(response3.message));
      }
    }
    setLoading(false);
  };

  const enableShippingSetting = async () => {
    setLoading(true);
    const res3 = await axios.get(
      `${process.env.REACT_APP_APIURL}/vendor/enabletorodshipping`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
    const response3 = await res3.data;
    if (response3.status_code == 200) {
      toast.success(response3.message);
      setShowShip(true)
    } else {
      toast.error(response3.err || response3.message);
    }
    setLoading(false);

  };

  const [filterText, setFilterText] = useState("");
  let filteredItems = courierPartners?.filter(
    (item) =>
      item.title?.toLowerCase().includes(filterText.toLowerCase()) ||
      item.title_arabic?.includes(filterText)
  );

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = (e) => {
      if (filterText) {
        setFilterText("");
        e.target.value = "";
      }
    };

    return (
      <div className="flex items-center">
        <FilterComponent2
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          setFilterText={setFilterText}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText]);

  const createShippingAccount = async () => {
    if (
      storeDetails?.torod_user_id === null ||
      storeDetails?.torod_user_id === undefined
    ) {
      setLoading(true);
      const url = `${process.env.REACT_APP_APIURL}/vendor/createtoroduser`;
      const res = await axios.get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });

      const response = await res.data;

      if (response) {
        if (response.status_code == 200 || response.status_code == 400) {
          setLoading(false);
          toast.success(response.message);
        } else {
          setLoading(false);
          if (typeof response.message === "string") {
            toast.error(response.message);
          } else {
            toast.error(JSON.stringify(response.message));
          }
        }
      }
    }
  };

  return (
    loading?<LoadingSpinner />:(
    <div>
      {showShip ? (
        <div>
          <div className="flex justify-between mb-5 mt-2">
            <div className="flex items-center">
              <h1 className="text-lg">
                {t("Available")} {t("Shipping_Methods")}
              </h1>
            </div>
            {subHeaderComponentMemo}
          </div>
          <div className="bg-gray-50 p-3">
            {filterText.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {filterText &&
                  filteredItems.map((partner) => (
                    <div key={partner.id}>
                      <Partners
                        partner={partner}
                        setPartnerInfo={setPartnerInfo}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {courierPartners.map((partner) => (
                  <div key={partner.id}>
                    <Partners
                      partner={partner}
                      setPartnerInfo={setPartnerInfo}
                    />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <h3 className="mb-4">
            {t("Shipping_Methods")} ({t("How It Works")}):{" "}
          </h3>

          <div className="p-4 border border-gray-200 bg-white shadow-sm ">
            <div className="flex justify-between mb-4 sm:my-4 w-full xl:w-9/12 flex-col sm:flex-row">
              <div className="my-4 sm:my-0">
                <h4 className="text-xl font-bold">
                  {" "}
                  {selectLanguage == "ar"
                    ? "إنشاء مستودع"
                    : "Create Shipping Account"}
                </h4>
                <p>
                  {selectLanguage == "ar"
                    ? "تسجيل الدخول إلى حسابك الحالي لتمكين إعدادات الشحن"
                    : "Create your shipping account to enable shipping settings"}
                </p>
              </div>

              <button
                onClick={createShippingAccount}
                disabled={storeDetails?.torod_user_id !== null}
                className="btn-primary rounded-lg px-4 py-2 h-fit my-auto max-w-[13rem] font-semibold md:w-56 w-1/2 hover:text-white disabled:opacity-50"
              >
                {selectLanguage == "ar"
                  ? "إنشاء مستودع"
                  : "Create Shipping Account"}
              </button>
            </div>

            <div className="flex justify-between my-4 w-full xl:w-9/12 flex-col sm:flex-row">
              <div className="my-4 sm:my-0">
                <h4 className="text-xl font-bold">
                  {selectLanguage == "ar"
                    ? "إنشاء مستودع الشحن"
                    : "Create Shipping Warehouse"}
                </h4>
                <p className="w-11/12">
                  {selectLanguage == "ar"
                    ? "إنشاء مستودع الشحن إذا لم تقم بإنشائه حتى الآن. يرجى تجاهل هذه الخطوة إذا كنت قد قمت بإنشاء مستودع الشحن بالفعل."
                    : "Create your warehouse if you have not created till now. Please ignore this step if you have already created your warehouse"}
                </p>
              </div>
              <button
                onClick={createShippingWarehouse}
                className={`btn-primary rounded-lg px-4 py-2 h-fit my-auto max-w-[13rem] font-semibold md:w-56 w-1/2 hover:text-white disabled:opacity-50 ${
                  storeDetails.torod_warehouse_id
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={storeDetails.torod_warehouse_id ? true : false}
              >
                {selectLanguage == "ar" ? "إنشاء مستودع" : "Create Warehouse"}
              </button>
            </div>

            <div className="flex justify-between my-4 w-full xl:w-9/12 flex-col sm:flex-row">
              <div className="my-4 sm:my-0">
                <h4 className="text-xl font-bold">
                  {selectLanguage == "ar" ? "تمكين الشحن" : "Enable Shipping"}
                </h4>
                <p className="w-11/12">
                  {selectLanguage == "ar"
                    ? "لتمكين الشحن، انقر على الزر، تجاهل هذه الخطوة إذا تمت المهمة بالفعل"
                    : "To enable shipping click the button, ignore this step if already done"}
                </p>
              </div>

              <button
                onClick={enableShippingSetting}
                className={`btn-primary rounded-lg px-4 py-2 h-fit my-auto font-semibold max-w-[13rem] md:w-56 w-1/2 hover:text-white disabled:opacity-50 ${
                  storeDetails.torod_shipping_enabled
                    ? "cursor-not-allowed"
                    : "cursor-pointer"
                }`}
                disabled={storeDetails.torod_shipping_enabled ? true : false}
              >
                {selectLanguage == "ar"
                  ? "تمكين الشحن"
                  : "Enable Shipping Setting"}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>)
  );
};

export default ShippingMethods;
